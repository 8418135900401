import React from "react";
import SEO from "../components/SEO";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

export default function Page(props) {
  return (
    <>
      <SEO
        title={props.title}
        description={props.description}
      />
      <Navbar />
      <div className="siteBackground">
        <div className="wrapper">
        {props.children}
        </div>
    </div>
      <Footer dark={true} />
    </>
  );
}