import React from "react";
import { graphql } from "gatsby";
import Page from "../layout/Page";
import QuizCard from "../components/Quiz/QuizCard";

export default function Quiz({ data }) {
    const allQuiz = data.allQuizJson.edges

    return (
        <>
            <Page title="Våra quiz" description={"Lär dig mer med våra studiequiz inom olika skolämnen såsom matematik, kemi, biologi och fysik."}>
            <br/>
            <h1>Våra olika quiz</h1>
            <p>Här nedan hittar du våra olika quiz om olika skolämnen.</p>
                <div className="quiz-container">
                    {
                        allQuiz.map((quiz) => {
                            return (
                                <QuizCard {...quiz.node} />
                            )
                        })
                    }
                </div>
                <br/>
            </Page>
        </>
    );
}

export const pageQuery = graphql`
  query quizes {
    allQuizJson {
        edges {
          node {
            title
            url
            description
          }
        }
      } 
  }
`;
