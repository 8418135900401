import React from "react";
import { Link } from "gatsby";

export default function QuizCard(props) {

  return (
    <Link to={"/quiz"+props.url} className="linkCard">
      <div className="blogCard">
        <div className="blogCardTextSection">
          <h4>{props.title}</h4>
          <p>{props.description}</p>
          <button className="btnStyle">Testa Quizet →</button>
        </div>
      </div>
    </Link>
  );
}
